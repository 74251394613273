
export interface menu {
  header?: string;
  title?: string;
  icon?: string;
  to?: string;
  divider?: boolean;
  getURL?: boolean;
  chip?: string;
  chipColor?: string;
  chipVariant?: string;
  chipIcon?: string;
  children?: menu[];
  disabled?: boolean;
  type?: string;
  subCaption?: string;
}

const sidebarItem: menu[] = [
  {
    title: 'Home',
    icon: 'mdi-home',
    to: '/',
  },

  {
    title: 'Tenant',
    icon: 'mdi-account-tie',
    to: '/tenants',
  },

  {
    title: 'Admin',
    icon: 'mdi-account-key',
    to: '/admins',
  },

  {
    title: 'User',
    icon: 'mdi-account-group',
    to: '/users',
  },

  {
    title: 'Device',
    icon: 'mdi-monitor',
    to: '/devices',
  },

  {
    title: 'Location',
    icon: 'mdi-map-marker',
    to: '/locations',
  },
];

export default sidebarItem;
