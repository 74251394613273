<script setup lang="ts">
import { ref, watch } from 'vue';
import { useCustomizerStore } from '@/stores/customizer';
import ProfileDD from './ProfileDD.vue';

const admin = await useAdmin();
const customizer = useCustomizerStore();
const priority = ref(customizer.setHorizontalLayout ? 0 : 0);
watch(priority, (newPriority) => {
  priority.value = newPriority;
});

</script>

<template>
    <v-app-bar elevation="0" :priority="priority" height="74" class="px-sm-10 px-5">
        <v-btn class="hidden-md-and-down mr-5 ml-0" color="primary" icon aria-label="sidebar button" rounded="sm"
            variant="text"  @click.stop="customizer.SET_MINI_SIDEBAR(!customizer.mini_sidebar)" style="width: 50px; height: 50px;">
            <v-icon size="36">mdi-menu</v-icon> 
        </v-btn>
        <v-btn class="hidden-lg-and-up mr-5 ml-0" color="primary" icon rounded="sm" variant="text" size="large"
      @click.stop="customizer.SET_SIDEBAR_DRAWER()" 
      style="width: 50px; height: 50px;">
      <v-icon size="36">mdi-menu</v-icon>
    </v-btn>
    <v-spacer />
    <!-- User Profile -->
    <v-menu v-if="admin" :close-on-content-click="false" offset="8, 0">
      <template v-slot:activator="{ props }">
        <v-btn class="profileBtn mr-0 d-flex align-center justify-center" aria-label="profile" variant="tonal"
          rounded="lg" color="primary" style="padding: 20px;" v-bind="props">
          <v-icon size="30" class="mr-2">mdi-account-circle</v-icon>
          <span class="ml-2" style="font-weight: 500; font-size: 16px;">{{ admin.name }}</span>
        </v-btn>
      </template>
      <v-sheet rounded="md" width="350">
        <ProfileDD />
      </v-sheet>
    </v-menu>
  </v-app-bar>
</template>
<style scoped>
@media (max-width: 600px) {
  .v-btn {
    display: block;
  }
}


</style>