<template>
    <v-app>
        <AdminSidebar />
      <v-main>
        <AdminHeaderSidebarHeader/>
        <v-container class="px-sm-10 px-5">
        <slot />
        </v-container>
      </v-main>
    </v-app>
  </template>

<script setup lang="ts">
useHead({
  titleTemplate: (titleChunk) => titleChunk ? `${titleChunk} | Admin Panel` : 'Admin Panel'
})
</script>