<template>
  <v-navigation-drawer left v-model="customizer.Sidebar_drawer" elevation="0" rail-width="70" mobile-breakpoint="sm" app
    class="leftSidebar" :rail="customizer.mini_sidebar">


    <!-- Logo part, hidden in mini sidebar but space remains -->
    <div class="logo-container" :class="{ 'collapsed': customizer.mini_sidebar }">
      <NuxtLink to="/">
        <img src="/prismatic-logo.svg" alt="Prismatic Logo" class="logo" />
      </NuxtLink>
    </div>

    <!-- Navigation items -->
    <perfect-scrollbar class="scrollnavbar">
      <v-list aria-busy="true" class="px-2" aria-label="menu list">
        <template v-for="(item, i) in sidebarMenu" :key="i">
          <SidebarNavGroup v-if="item.header" :item="item" :key="item.title" />
          <v-divider v-else-if="item.divider" />
          <SidebarNavCollapse v-else-if="item.children" :item="item" :level="0" class="leftPadding" />
          <SidebarNavItem v-else :item="item" />
        </template>
      </v-list>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { shallowRef } from 'vue';
import { useCustomizerStore } from '@/stores/customizer';
import sidebarItems from './sidebarItem';

const customizer = useCustomizerStore();
const sidebarMenu = shallowRef(sidebarItems);

</script>

<style scoped>

/* Sidebar content, ensuring the layout remains unchanged */
.logo-container {
  padding: 20px;
  text-align: center;
}

.logo {
  /* width: 180px; */
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 10px;
}

.logo-container.collapsed {
  visibility: hidden;
  height: 120px; }


</style>
