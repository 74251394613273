<script setup lang="ts">
import { ref } from 'vue';
const admin = await useAdmin();

const tab = ref(null);
const token = useToken();


const profiledata1 = ref([
  {
    title: 'Edit profile',
    icon: 'mdi-pencil',
    to: '/',
  },

]);

const profiledata2 = ref([
  {
    title: 'Email Notification Setting',
    icon: 'mdi-cog-outline',
    to: '/admins'
  }
]);

async function onSubmit() {
  try {
    await $fetch("/api/v1/admin/auth/logout", {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    admin.value = null
    useCookie("token").value = null
    navigateTo("/login");
  } catch (error) {
    // TODO: display error message
  }
}
</script>

<template>
  <!-- ---------------------------------------------- -->
  <!-- profile DD -->
  <!-- ---------------------------------------------- -->
  <div v-if="admin">
    <div class="d-flex align-center pa-5">
      <v-icon size="40" class="mr-2">mdi-account-circle</v-icon>
      <div>
        <h6 class="text-subtitle-1 mb-0">{{ admin.name }}</h6>
        <p class="text-caption text-lightText mb-0">{{ admin.email }}</p>
      </div>

    </div>
    <v-tabs v-model="tab" color="primary" grow>
      <v-tab value="111">
        <v-icon class="mr-2">mdi-account-outline</v-icon>
        Profile
      </v-tab>
      <v-tab value="222">
        <v-icon class="mr-2">mdi-cog-outline</v-icon>
        Setting
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <perfect-scrollbar style="height: calc(100vh - 300px); max-height: 240px">
      <v-window v-model="tab">
        <v-window-item value="111">
          <v-list class="px-2" aria-label="profile list" aria-busy="true">
            <v-list-item v-for="(item, index) in profiledata1" :key="index" :to="item.to" color="primary"
              base-color="primary" rounded="md" :value="item.title">
              <template v-slot:prepend>
                <v-icon class="mr-4">{{ item.icon }}</v-icon>
              </template>

              <v-list-item-title class="text-h6">{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onSubmit" color="red" base-color="red" rounded="md">
              <template v-slot:prepend>
                <v-icon class="mr-4">mdi-logout</v-icon>
              </template>

              <v-list-item-title class="text-h6">Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-window-item>
        <v-window-item value="222">
          <v-list class="px-2" aria-label="profile list" aria-busy="true">
            <v-list-item v-for="(item, index) in profiledata2" :key="index" :to="item.to" color="primary"
              base-color="primary" rounded="md" :value="item.title">
              <template v-slot:prepend>
                <v-icon class="">{{ item.icon }}</v-icon>
              </template>

              <v-list-item-title class="text-h6">{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-window-item>
      </v-window>
    </perfect-scrollbar>
  </div>
</template>
